import React, {useContext} from 'react';
import Providers from '~src/components/Providers';
import {ContentView, PageTemplate, Modals, Articles} from '~src/components';
import {LocaleContext} from '~src/context';
import {IPage} from '~src/types';
import Seo from '~src/components/Seo';

interface ICalculation1Props extends IPage {}

const ArticlesPage: React.FC<ICalculation1Props> = ({location, navigate, path}) => {
  const {
    pages: {articlesPage, index},
  } = useContext(LocaleContext);
  return (
    <Providers>
      <Seo
        title={articlesPage.meta.title}
        description={index.meta.description}
        keywords={index.meta.keywords}
        path={path}
      />
      <PageTemplate
        breadcrumbs={[...articlesPage.breadcrumbs, articlesPage.header1]}
        breadcrumbsSource={articlesPage.breadcrumbsSource}
      >
        <ContentView>
          <Articles />
        </ContentView>
      </PageTemplate>
      <Modals navigate={navigate} path={path} location={location} />
    </Providers>
  );
};

export default ArticlesPage;
